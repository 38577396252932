import styles from "../../styles/referral.module.scss";
import { Field, FieldArray, useFormikContext} from "formik";
import React from "react";
import * as Yup from "yup";
import {Add16, Subtract16} from "@carbon/icons-react";
import {Button} from "carbon-components-react";



export const historyDataInitialValues = {
    history: [
        {
            date: '',
            type: '',
            where: '',
        }
    ],
}

export const historyDataValidationSchema = Yup.object().shape({
//TODO validation schema

});




export default function HistoryData(props) {

    const { values } = useFormikContext();

    return (
        <fieldset className={styles.formSection}>
            <legend>Relevant prior imaging</legend>

            <div className={styles.formRow}>
                <div className={`${styles.question} ${styles.questionFixed} ${styles.matrixHeader}`}>
                    Date
                </div>
                <div className={`${styles.question} ${styles.questionFixed} ${styles.matrixHeader}`}>
                    Type
                </div>
                <div className={`${styles.question} ${styles.questionGrow} ${styles.matrixHeader}`}>
                    Where
                </div>
                <div className={styles.questionButtons}>
                    &nbsp;
                </div>
            </div>

            <FieldArray name={"history"}>
                {({push, remove}) => (
                    <div>
                        {
                            values.history.length > 0 &&
                            values.history.map((history, index) => (
                                <div className={styles.formRow} key={index}>
                                    <div className={`${styles.question} ${styles.questionFixed}`}>



                                        <Field name={`history.${index}.date`}
                                               type="text"
                                               autoComplete="off"

                                               className={`${styles.matrixInput}`}
                                        />
                                    </div>
                                    <div className={`${styles.question} ${styles.questionFixed}`}>


                                        <Field name={`history.${index}.type`}
                                               type="text"
                                               autoComplete="off"
                                               className={`${styles.matrixInput}`}
                                        />
                                    </div>
                                    <div className={`${styles.question} ${styles.questionGrow}`}>


                                        <Field name={`history.${index}.where`}
                                               type="text"
                                               autoComplete="off"
                                               className={`${styles.matrixInput}`}
                                        />
                                    </div>
                                    <div className={`${styles.question} ${styles.questionButtons}`}>
                                        {index >= 0 && index === values.history.length - 1 ? (
                                            <Button renderIcon={Add16}
                                                    iconDescription="Add more history"
                                                    hasIconOnly
                                                    size="sm"
                                                    tabIndex="20"
                                                    onClick={
                                                        () => {
                                                            push({history: ""});
                                                        }
                                                    }
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        {index > 0 && index === values.history.length - 1 ? (

                                            <Button renderIcon={Subtract16}
                                                    iconDescription="Remove history entry"
                                                    className="bx--btn--icon-only--right--button"
                                                    hasIconOnly
                                                    size="sm"
                                                    tabIndex="30"
                                                    onClick={
                                                        () => {
                                                            remove(index);
                                                        }
                                                    }
                                            />
                                        ) : (
                                            <></>
                                        )}

                                    </div>
                                </div>


                            ))


                        }
                    </div>


                )
                }

            </FieldArray>


        </fieldset>

    )
}