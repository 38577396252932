import styles from '../styles/referral.module.scss'

import React, {Component} from "react";
import AuthService from "../util/AuthService";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {Redirect} from "react-router-dom";
import MessageDisplay from "../component/MessageDisplay";
import {Button, ButtonSet} from "carbon-components-react";
import ActionButton from "../component/ActionButton";

const RegisterSchema = Yup.object().shape({
    username: Yup.string().trim().email('Invalid email address').required('Please ensure your email address is correct.'),
    password: Yup.string().trim().required('Please enter your password.')
});

const initialValues = {
    username: "",
    password: ""
}

export default class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            message: "",
            redirectTo: ""
        };
    }


    componentDidMount() {

        const currentUser = AuthService.getCurrentUser();
        //Redirect to Home page if we're already logged in
        if (currentUser) this.setState({redirectTo: "/home"});
    }


    render() {

        //process redirects
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }


        return (


            <div>
                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Login</h3>
                </div>


                <p>Please note use is restricted to authorised individuals. </p>

                <MessageDisplay message={this.state.message} />


                <Formik


                    initialValues={initialValues}
                    validationSchema={RegisterSchema}


                    onSubmit={(values) => {
                        this.setState({
                            submitting: true,
                            message: ""
                        })
                        AuthService.login(values.username, values.password).then(
                            () => {
                                this.props.history.push("/home");
                                window.location.reload();
                            },
                            error => {
                                const resMessage =
                                    (error.response &&
                                        error.response.data &&
                                        error.response.data.message) ||
                                    error.message ||
                                    error.toString();

                                this.setState({
                                    submitting: false,
                                    message: resMessage
                                });
                            }
                        );
                    }}


                >
                    {({errors, touched}) => (


                        <Form>



                            <fieldset className={styles.formSection}>
                                <legend>Login Credentials</legend>

                                <div className={styles.formRow}>
                                    <div className={`${styles.question} ${styles.questionGrow}`}>
                                        <label className={styles.defaultLabel} htmlFor="username">Email address</label>
                                        <Field name="username"
                                               className={errors.username && touched.username ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                               autoComplete="off"/>
                                        <ErrorMessage className={styles.validationWarning} component="div"
                                                      name={"username"}/>
                                    </div>
                                </div>

                                <div className={styles.formRow}>
                                    <div className={`${styles.question} ${styles.questionGrow}`}>
                                        <label className={styles.defaultLabel} htmlFor="password">Password</label>
                                        <Field name="password"
                                               type="password"
                                               className={errors.password && touched.password ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                               autoComplete="off"/>
                                        <ErrorMessage className={styles.validationWarning} component="div"
                                                      name={"password"}/>
                                    </div>
                                </div>


                            </fieldset>

                            <ButtonSet className={styles.formControlsRight}>
                                <Button type = "reset" kind = "secondary" >Clear form</Button>
                                <ActionButton hasActivity = {this.state.submitting} backgroundColour = "#ffffff">Login</ActionButton>
                            </ButtonSet>



                        </Form>
                    )}


                </Formik>



            </div>


        )
    }

}

