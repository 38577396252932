import {KeyboardDatePicker} from "@material-ui/pickers";
import React from "react";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider} from "@material-ui/styles";
import {createMuiTheme} from "@material-ui/core";



export default function DatePickerField(props) {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main:'#772583',
                secondary:'#e10098',
            }
        }
    })

    return (

        <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <ThemeProvider theme={theme}>
                <KeyboardDatePicker className="thisnewclass"

                                    allowKeyboardControl={true}
                                    animateYearScrolling={false}
                                    maskChar="_"
                                    autoOk={true}
                                    disableFuture={true}
                                    name={props.field.name}
                                    value={props.field.value}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    onChange={date=>props.form.setFieldValue(props.field.name, date, false)} {
                                        ...props
                                    }
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}
