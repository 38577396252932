import styles from '../styles/referral.module.scss'
import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import AuthService from "../util/AuthService";
import {Button, ButtonSet} from "carbon-components-react";


export default class Success extends Component {


    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            redirectTo: "",
            message: ""
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();
        this.setState({
            user: user,
            ready: true
        })
    }


    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
               <div>
                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>AECC Diagnostic Services</h3>
                </div>

                    <fieldset className={styles.formSection}>
                        <legend>Thank You</legend>

                        {(this.state.user && this.state.ready) ? (
<div>
                        <p className={styles.instruction}>Your request has been received.</p>



    <ButtonSet>
        <Link to = "/logout" className = "bx--btn--surround">
            <Button as = "div" role = "button" kind="secondary" tabIndex="20" size="md" type="button" className="bx--btn--wide">
                Logout
            </Button>
        </Link>
        <Link to = "/home" className = "bx--btn--surround">
            <Button as = "div" role = "button" kind="primary" tabIndex="10" size="md" type="button" className="bx--btn--wide">
                Return to services
            </Button>
        </Link>
    </ButtonSet>



</div>

                            ) : (
                            <div>
                                <p className={styles.instruction}>Your registration has been received.</p>



                                <Link to = "/login" className = "bx--btn--surround">
                                    <Button as = "div" role = "button" kind="primary" tabIndex="10" size="md" type="button" className="bx--btn--wide">
                                        Login
                                    </Button>
                                </Link>

                            </div>
                        )}

               </fieldset>
      </div>
        )
    }

}

