import React, {Component} from "react";
import styles from "../styles/referral.module.scss";

export default class MessageDisplay extends Component {

    render() {

        if (this.props.message.length > 1) {
            return (
                <fieldset id="messageBox" className={styles.serverMessage}>
                    <p>{this.props.message}</p>
                </fieldset>
            );
        } else {
            return null;
        }

    }
}
