import styles from "../../styles/referral.module.scss";
import {ErrorMessage, Field, FieldArray, FormikContext} from "formik";
import React, {Component} from "react";
import * as Yup from "yup";
import {Button} from "carbon-components-react";
import {Add16, Subtract16} from "@carbon/icons-react";



export const ultrasoundDataInitialValues = {
    ultrasound: [
        {
            examination: "",
        }
    ],
}

export const ultrasoundDataValidationSchema = Yup.object().shape({
    ultrasound: Yup.array().of(
        Yup.object().shape({
            examination: Yup.string().trim().required('Please indicate a required examination.'),
        })
    ).required('Please tell us about the imaging required.').min(1,'There must be at least 1 request.'),
});



export default class UltrasoundData extends Component {



    static contextType = FormikContext;
render()
{

    const {values} = this.context;

    return (


        <fieldset className={styles.formSection}>
            <legend>Requested Examination(s)</legend>
            <div className={styles.formRow}>
                <div className={`${styles.question} ${styles.questionGrow} ${styles.matrixHeader} `}>
                    Examination:
                </div>
                <div className={styles.questionButtons}>
                    &nbsp;
                </div>
            </div>
            <FieldArray name={"ultrasound"}>
                {({push, remove}) => (
                    <div>
                        {
                            values.ultrasound.length > 0 &&
                            values.ultrasound.map((ultrasound, index) => (
                                <div className={styles.formRow} key={index}>
                                    <div className={`${styles.question} ${styles.questionGrow}`}>


                                        <Field name={`ultrasound.${index}.examination`}
                                               type="text"
                                               autoComplete="off"

                                               className={`${styles.matrixInput}`}
                                        />

                                        <ErrorMessage className={styles.validationWarning} component="div"
                                                      name={`ultrasound.${index}.examination`}/>

                                    </div>

                                    <div className={`${styles.question} ${styles.questionButtons}`}>
                                        {index >= 0 && index === values.ultrasound.length - 1 ? (
                                            <Button renderIcon={Add16}
                                                    iconDescription="Add another examination request"
                                                    hasIconOnly
                                                    size="sm"
                                                    tabIndex="20"
                                                    onClick={
                                                        () => {
                                                            push({ultrasound: ""});
                                                        }
                                                    }
                                            />

                                        ) : (
                                            <span>&nbsp;</span>
                                        )}
                                        {index > 0 && index === values.ultrasound.length - 1 ? (

                                            <Button renderIcon={Subtract16}
                                                    iconDescription="Remove requested examination"
                                                    className="bx--btn--icon-only--right--button"
                                                    hasIconOnly
                                                    size="sm"
                                                    tabIndex="30"
                                                    onClick={
                                                        () => {
                                                            remove(index);
                                                        }
                                                    }
                                            />
                                        ) : (
                                            <span>&nbsp;</span>
                                        )}

                                    </div>
                                </div>


                            ))


                        }
                    </div>


                )
                }

            </FieldArray>


        </fieldset>


    )
}
}
