import styles from "../../styles/referral.module.scss";
import {ErrorMessage, Field, useFormikContext} from "formik";
import React from "react";
import * as Yup from "yup";


export const mriSafetyDataInitialValues = {
    metalDevice: "",
    metalInEyes: "",
    metalDetails: "",
}

export const mriSafetyDataValidationSchema = Yup.object().shape({
    metalDevice: Yup.string().required('Please acknowledge the safety notice'),
    metalInEyes: Yup.string().required('Please acknowledge the safety notice'),
    metalDetails: Yup.string().trim()
        .when('metalDevice', {
            is: 'true',
            then: Yup.string().required('Please provide us with some details on the metal objects'),
        })
        .when('metalInEyes', {
            is: 'true',
            then: Yup.string().required('Please provide us with some details on the metal objects'),
        })
});


export default function MriSafetyData(props) {

    const {values, errors, touched} = useFormikContext();

    return (

        <fieldset className={styles.formSection}>
            <legend>Safety check</legend>
            <div className={`${styles.infoBlock} ${styles.formRow}`}>
                You, as the referring clinician, are required to assess the patient safety for scans. This is
                recommended by the MHRA.

            </div>

            <div className={styles.formRow}>

                <div className={`${styles.question} ${styles.questionGrow}`}>
                    <div>
                    <label className={styles.infoBlockNormal} htmlFor="metalDevice">Does the patient have any implanted
                        metallic devices? (e.g. cardiac pacemaker, artificial heart valve, cerebral aneurysm clips, Neurotransmitter, cochlear implant etc))</label>
                    </div>
                </div>
                <div className={`${styles.question} ${styles.questionFixed}`}>
                    <div className={styles.radioGroupButtonSurround}>
                        <div className={styles.inputButtonElementSurround}>
                            <Field id="metalDeviceYes" name="metalDevice" type="radio"
                                   className={styles.inputRadio}
                                   value="true"
                                   autoComplete="off"
                            />

                            <label htmlFor="metalDeviceYes">Yes</label>
                        </div>
                        <div className={styles.inputButtonElementSurround}>
                            <Field id="metalDeviceNo" name="metalDevice" type="radio"
                                   className={styles.inputRadio}
                                   value="false"
                                   autoComplete="off"
                            />

                            <label htmlFor="metalDeviceNo">No</label>
                        </div>
                        <ErrorMessage className={styles.validationWarning} component="div" name={"metalDevice"}/>
                    </div>
                </div>

            </div>

            <div className={styles.formRow}>


                <div className={`${styles.question} ${styles.questionGrow}`}>

                    <label className={styles.infoBlockNormal} htmlFor="metalEyes">Is the patient known to have metallic
                        fragments in the eyes? If yes, it is mandatory to exclude metal foreign bodies in the eyes
                        by
                        orbital x-ray. If a metallic foreign body is detected, unable to proceed with MRI.
                    </label>
                </div>



                <div className={`${styles.question} ${styles.questionFixed}`}>

                    <div className={styles.radioGroupButtonSurround}>
                        <div className={styles.inputButtonElementSurround}>
                            <Field id="metalEyesYes" name="metalInEyes" type="radio"
                                   className={styles.inputRadio}
                                   value="true"
                                   autoComplete="off"
                            />

                            <label htmlFor="metalEyesYes">Yes</label>
                        </div>
                        <div className={styles.inputButtonElementSurround}>
                            <Field id="metalEyesNo" name="metalInEyes" type="radio"
                                   className={styles.inputRadio} value="false"
                                   autoComplete="off"
                            />

                            <label htmlFor="metalEyesNo">No</label>
                        </div>
                        <ErrorMessage className={styles.validationWarning} component="div" name={"metalInEyes"}/>
                    </div>

                </div>
            </div>

            {(values.metalInEyes === "true" || values.metalDevice === "true") && (
                <div className={styles.formRow}>
                    <div className={`${styles.question} ${styles.questionGrow}`}>
                        <label className={styles.defaultLabel} htmlFor="metalDetails">Details of the metal objects present<span className={styles.additionalInfo}> e.g, nature, material and location of known objects</span></label>
                        <Field name="metalDetails"
                               as="textarea"
                               autoComplete="off"
                               className={errors.metalDetails && touched.metalDetails ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                        />
                        <ErrorMessage className={styles.validationWarning} component="div"
                                      name={"metalDetails"}/>
                    </div>
                </div>
            )}
        </fieldset>


    )
}
