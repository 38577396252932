import styles from "../../styles/referral.module.scss";
import {ErrorMessage, Field, FormikContext} from "formik";
import React, {Component} from "react";
import * as Yup from "yup";


export const patientExtraDataInitialValues = {
    patientWeight: "",
    patientHeight: "",
    patientSpecialRequirements: "",
    patientClaustrophobic: "",
    patientBariatricConcerns: "",
    patientLearningNeeds: "",
}
/*
export const patientExtraDataValidationSchema = Yup.object().shape({
    patientWeight: Yup.number().positive().integer().max(250),
    patientHeight: Yup.number().positive().integer().max(250),
});
*/

export const patientExtraDataValidationSchema = Yup.object().shape({
    patientWeight: Yup.string().required('Patient\'s weight is required'),
    patientHeight: Yup.string().required('Patient\'s height is required'),
});


export default class PatientExtraData extends Component {

    static contextType = FormikContext;



    componentDidMount() {
        //runs after component output rendered to dom
        //console.debug("patientDataExtra: DidMount")
    }

    componentWillUnmount() {
        //runs if the component is removed from the DOM
        //console.debug("patientDataExtra: WillUnmount")
    }

    render() {


        const {errors, touched} = this.context;

        return (
            <fieldset className={styles.formSection}>
                <legend>Patient comfort</legend>


                <div className={styles.formRow}>

                    <div className={`${styles.question} ${styles.questionGrow}`}>
                        <div className={styles.formRow}>

                        <div className={`${styles.question} ${styles.questionGrow}`}>
                            <label className={styles.defaultLabel} htmlFor="patientWeight">Weight</label>
                            <Field name="patientWeight"
                                   autoComplete="off"
                                   className={errors.patientWeight && touched.patientWeight ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                            />
                            <ErrorMessage className={styles.validationWarning} component="div" name={"patientWeight"}/>
                        </div>

                        <div className={`${styles.question} ${styles.questionGrow}`}>
                            <label className={styles.defaultLabel} htmlFor="patientHeight">Height</label>
                            <Field name="patientHeight"
                                   autoComplete="off"
                                   className={errors.patientHeight && touched.patientHeight ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                            />
                            <ErrorMessage className={styles.validationWarning} component="div" name={"patientHeight"}/>
                        </div>
                        </div>
                        <div className={styles.formRow}>
                            <div className={`${styles.question} ${styles.questionGrow}`}>
                                <label className={styles.defaultLabel} htmlFor="patientSpecialRequirements">Special
                                    requirements <span className={styles.additionalInfo}>will need oxygen or a wheel chair? Any need for an interpreter?</span></label>
                                <Field name="patientSpecialRequirements"
                                       as="textarea"
                                       autoComplete="off"
                                       className={errors.patientSpecialRequirements && touched.patientSpecialRequirements ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                />
                                <ErrorMessage className={styles.validationWarning} component="div"
                                              name={"patientSpecialRequirements"}/>
                            </div>
                        </div>
                    </div>
                                        <div className={`${styles.question} ${styles.questionGrow}`}>

                        <div className={styles.formRow}>

                            <div className={`${styles.question} ${styles.questionGrow}`}>
                                <label className={styles.defaultLabel} htmlFor="patientClaustrophobic">Does the patient
                                    suffer
                                    from Claustrophobia?</label>


                                <div className={styles.radioGroupButtonSurround}>
                                    <div className={styles.inputButtonElementSurround}>
                                        <Field className={styles.inputRadio}
                                               id="patientClaustrophobiaQuestion"
                                               name="patientClaustrophobic"
                                               type="checkbox"
                                        />
                                        <label htmlFor="patientClaustrophobiaQuestion">Yes</label>
                                    </div>

                                </div>
                                <ErrorMessage className={styles.validationWarning} component="div"
                                              name={"patientClaustrophobic"}/>
                            </div>
                        </div>
                        <div className={styles.formRow}>
                            <div className={`${styles.question} ${styles.questionFixed}`}>
                                <label className={styles.defaultLabel} htmlFor="patientBariatricConcerns">Are there Bariatric
                                    concerns?</label>


                                <div className={styles.radioGroupButtonSurround}>
                                    <div className={styles.inputButtonElementSurround}>
                                        <Field className={styles.inputRadio}
                                               id="bariatricConcernsQuestion"
                                               name="patientBariatricConcerns"
                                               type="checkbox"

                                        />

                                        <label htmlFor="bariatricConcernsQuestion">Yes</label>
                                    </div>

                                </div>


                                <ErrorMessage className={styles.validationWarning} component="div"
                                              name={"patientBariatricConcerns"}/>
                            </div>


                        </div>


                        <div className={styles.formRow}>
                            <div className={`${styles.question} ${styles.questionFixed}`}>
                                <label className={styles.defaultLabel} htmlFor="patientLearningNeeds">Are there additional
                                    communication or learning needs?</label>


                                <div className={styles.radioGroupButtonSurround}>
                                    <div className={styles.inputButtonElementSurround}>
                                        <Field className={styles.inputRadio}
                                               id="learningNeedsQuestion"
                                               name="patientLearningNeeds"
                                               type="checkbox"

                                        />

                                        <label htmlFor="learningNeedsQuestion">Yes</label>
                                    </div>

                                </div>


                                <ErrorMessage className={styles.validationWarning} component="div"
                                              name={"patientLearningNeeds"}/>
                            </div>
                        </div>


                    </div>
                </div>


            </fieldset>
        )

    }
}