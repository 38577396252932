import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router-dom'



//Logout after 30 minutes
const LOGOUT_AFTER = 30;

/**
 * Triggers and event after a period of inactivity that logs the client out and deletes the session tokens
 * Set to LOGOUT_AFTER minutes
 *
 * //TODO: Add in a second stage of early warning , eg pop up a 5minute left countdown timer
 *
 */
const SessionManager = (props) => {

    //Named component passed through to us from our router,
    //we are to be the HOC and handle its session activity.
    const { Component } = props;



    const history = useHistory()

    /**
     * Called after a period of inactivity
     */
    const handleLogout = (event) => {
        console.log("Logging out user due to idle timer ");

        //Push people to our logout page, it'll handle session deletion and redirecting to the login page
        history.push("/logout");
    }

    /**
     * Set up a timer to trigger our logout handler after LOGOUT_AFTER minutes of inactivity
     *
     */
    const {getLastActiveTime, getTotalIdleTime} = useIdleTimer({
        timeout: 1000 * 60 * LOGOUT_AFTER,
        onIdle: handleLogout,
        debounce: 500,
    })

    return <Component {...props}  />
}

export default SessionManager;