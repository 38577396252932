import React, {Component} from "react";
import AuthService from "../util/AuthService";

import styles from "../styles/referral.module.scss";



import {Accordion, AccordionItem, Tab, Tabs} from "carbon-components-react";



export default class Guidelines extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            redirectTo: "",
            message: ""
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({

            });
        }

        this.setState({
            user: user,
            ready: true
        })

    }


    render() {



        return (
            <div>
                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Diagnostic guidelines</h3>
                </div>


                <Tabs>
                    <Tab id="tab-1" label="Spine">


                        <Accordion>
                            <AccordionItem title="Possible atlanto-axial subluxation">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>Lateral C-spine –
                                    Neutral
                                    Flexion,
                                    Extension only following initial report
                                </p>
                                <p className = {styles.comment}>A single lateral cervical spine XR with the
                                    patient in supervised comfortable flexion
                                    should show any significant subluxation in
                                    patients with rheumatoid arthritis, Down’s
                                    syndrome, etc. If there is no subluxation on
                                    flexion, no further imaging is required. If
                                    subluxation is seen, an extension view will help
                                    to identify if the subluxation is fixed. MRI is recommended as follow-up for spinal cord integrity.
                                </p>

                            </AccordionItem>
                            <AccordionItem title="Non-traumatic neck pain, brachialgia, not improving with conservative care.">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP and Lateral

                                    (Peg View only in trauma)

                                </p>
                                <p className = {styles.comment}>
                                    Standard series. Obliques only at radiologists’ request.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Increasing neck pain nontraumatic. No “red flags”">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP Lateral

                                </p>
                                <p className = {styles.comment}>
                                    Standard series. Obliques only at radiologists’ request.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Increasing nontraumatic cervical radiculopathy. No “red flags”. Suspect disc herniation.">
                                <h6>MRI initial imaging</h6>

                            </AccordionItem>
                            <AccordionItem title="Prior cervical spine surgery. New or increasing nontraumatic neck pain or radiculopathy.">
                                <h6>Radiography initial imaging.</h6>
                                <p className = {styles.projection}>
                                    AP and Lateral,

                                    Review if upper C-spine then OM
                                </p>
                                <p className = {styles.comment}>
                                    Standard series. Obliques only at radiologists’ request.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Suspicion for infection with new or increased nontraumatic neck pain or radiculopathy. “Red flags” present">
                                <h6>MRI with and without contrast initial imaging. Radiography may be appropriate.</h6>
                                <p className = {styles.projection}>
                                    X-ray following initial report or where there is suspicion of infection or contraindication to MRI

                                </p>
                                <p className = {styles.comment}>
                                    MRI is the gold standard
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Known malignancy. Increasing nontraumatic neck pain or radiculopathy. No neurologic deficit. “Red flags” present">
                                <h6>MRI with or without contrast initial imaging. Radiography may be appropriate</h6>
                                <p className = {styles.projection}>
                                    X-ray undertaken if contraindication to MRI

                                </p>
                                <p className = {styles.comment}>

                                </p>
                            </AccordionItem>
                            <AccordionItem title="Acute traumatic neck pain with or without radiculopathy, suspected unilateral facet dislocation (post-traumatic torticollis) or fracture.">
                                <h6>Radiography initial imaging.</h6>
                                <p className = {styles.projection}>
                                    AP & Lateral &OM

                                </p>
                                <p className = {styles.comment}>
                                    Imaging indicated by NEXUS or CCR clinical criteria or posttraumatic torticollis.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Clinical indications of osteoporotic collapse, spondyloarthropathy, congenital/structural abnormalities with or without neurologic deficit.">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP and Lateral
                                </p>
                                <p className = {styles.comment}>
                                    Imaging indicated to inform manual therapy, prognosis, further imaging. Standard series. Obliques only by radiologists’ request.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Thoracic spine: posttraumatic vertebral collapse, osteoporotic collapse, abnormal kyphosis, Scheuermann, with or without neurologic deficit">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP and Lateral

                                </p>
                                <p className = {styles.comment}>
                                    Imaging indicated to inform manual therapy, prognosis, further imaging. Standard series.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Lumbar spine: acute on chronic, subacute or chronic low back pain  without radiculopathy. One or more of the following: low velocity trauma, osteoporosis, elderly individual, or chronic steroid use">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP & Lateral

                                </p>
                                <p className = {styles.comment}>
                                    If there remains concern MRI can be useful in cases of worsening neurologic deficit.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Lumbar spine: worsening acute on chronic, subacute or chronic low back pain with or without radiculopathy. One or more of the following: suspicion of cancer, infection or immunosuppression.">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP & Lateral


                                </p>
                                <p className = {styles.comment}>
                                    If there remains concern MRI is the next study.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Low back pain or radiculopathy. New or progressing symptoms or clinical findings with history of prior lumbar surgery.">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP & Lateral

                                    Flexion and extension following discussion


                                </p>
                                <p className = {styles.comment}>
                                    Weight bearing flexion and extension views can be helpful. MRI with contrast can differentiate recurrent disc herniation from epidural fibrosis.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Low back pain with suspected cauda equina syndrome or rapidly progressive neurologic deficit.">
                                <h6>MRI</h6>
                                <p className = {styles.projection}>
                                    Urgently

                                </p>
                                <p className = {styles.comment}>

                                </p>
                            </AccordionItem>
                            <AccordionItem title="Low back pain, acute with radiculopathy, disc herniation">
                                <h6>MRI</h6>

                            </AccordionItem>
                            <AccordionItem title="Low back pain, chronic, with one or more of the following: not improving with 4-6 weeks of conservative care, complex postural or structural deformity, considering surgical consult.">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP & Lateral

                                </p>
                                <p className = {styles.comment}>
                                    Standard series only. Obliques only at radiologists’ request.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Low back pain, chronic, suspect pars fracture.  Assessment of bone and joint integrity prior to the application of manual procedures, particularly considering age and mode of onset.">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP & Lateral

                                </p>
                                <p className = {styles.comment}>
                                    Standard series only. Obliques only at radiologists’ request.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Chronic back pain:  suspected sacroiliitis/spondyloarthropathy. Inflammatory back pain symptoms.">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP & Lateral

                                </p>
                                <p className = {styles.comment}>
                                    Sacroiliac joints. Spinal radiographs complimentary if indicated.
                                    If radiographs are negative or equivocal: MRI without contrast.

                                </p>
                            </AccordionItem>
                            <AccordionItem title="Spine ankylosis, suspected fracture. DISH or AS as underlying conditions.">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP & Lateral

                                </p>
                                <p className = {styles.comment}>
                                    CT if radiography findings are negative or equivocal.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Suspected thoracic, lumbar spine, sacral trauma/fracture">
                                <h6>Radiography</h6>
                                <p className = {styles.projection}>
                                    AP& Lateral

                                    For Sacral injury Sacral AP instead of AP


                                </p>
                                <p className = {styles.comment}>
                                    Assessment of bone and joint.
                                </p>
                            </AccordionItem>
                            <AccordionItem title="Coccyx trauma/fracture">
                                <h6>Radiography</h6>
                                <p className = {styles.projection}>
                                    Lateral only

                                </p>
                                <p className = {styles.comment}>
                                    Only where treatment by manual therapy is considered
                                </p>
                            </AccordionItem>
                        </Accordion>



                    </Tab>
                    <Tab id="tab-2" label="Lower Limb">
                        <Accordion>
                            <AccordionItem title="Suspected stress(fatigue. insufficiency) fracture, including lower extremity">
                                <h6>Radiography initial imaging.</h6>
                                <p className = {styles.projection}>
                                    two views – 10 days post injury
                                </p>
                                <p className = {styles.comment}>
                                    Ultrasound may be of value
                                </p>
                            </AccordionItem>

                            <AccordionItem title="Suspected insufficiency fracture in the pelvis or upper femur, with osteoporosis and or bisphosphonate use">
                                <h6>Radiography initial imaging.</h6>
                                <p className = {styles.projection}>
                                    AP Pelvis and HBL
                                </p>
                                <p className = {styles.comment}>
                                    Subtrochanteric region must be included if symptomatic. If radiographs are negative or equivocal MRI is the next study.
                                </p>
                            </AccordionItem>

                            <AccordionItem title="Arthroplasty, total hip arthroplasty, suspect component malposition, infection heterotopic ossification, aseptic loosening, particle disease, periprosthetic fracture.">
                                <h6>Radiography</h6>
                                <p className = {styles.projection}>
                                    AP pelvis and turned lateral – over 50

                                    AP and Lateral hip only for under 50

                                </p>
                                <p className = {styles.comment}>
                                    Ultrasound may be of value
                                </p>
                            </AccordionItem>

                            <AccordionItem title="Arthroplasty, total hip arthroplasty, trochanteric pain,suspect abductor injury or trochanteric bursitis, iliopsoas bursitis or tendinitis. ">
                                <h6>Diagnostic ultrasound</h6>
                                <p className = {styles.projection}>

                                </p>
                                <p className = {styles.comment}>
                                    Ultrasound may be of value
                                    MRI may be helpful for further follow up.

                                </p>
                            </AccordionItem>

                            <AccordionItem title="Osteonecrosis of the hip">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP Pelvis and HBL
                                </p>
                                <p className = {styles.comment}>
                                    AP pelvis and lateral of the symptomatic hip should be included. If findings are negative or femoral head lucencies suspicious for osteonecrosis are present MRI is next best study.
                                </p>
                            </AccordionItem>

                            <AccordionItem title="Knee pain, acute, posttraumatic. One or more of the following: focal tenderness, effusion, inability to bear weight.">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.projection}>
                                    AP  & Lateral  weight bearing unless trauma
                                </p>
                                <p className = {styles.comment}>
                                    If radiographs are negative and suspected occult fracture and or internal derangement: MRI is next imaging procedure
                                </p>
                            </AccordionItem>

                            <AccordionItem title="Knee pain, chronic or suspect osteochondritis dissecans, chondrocalcinosis. ">
                                <h6>Radiography initial imaging </h6>
                                <p className = {styles.projection}>
                                    AP and Lateral weight bearing

                                    Tunnel view following initial report

                                </p>

                            </AccordionItem>

                            <AccordionItem title="Knee pain, chronic. Initial radiographs show osteochondritis dissecans, loose bodies or history of cartilage or meniscal repair, prior osseous injury">
                                <h6>MRI without contrast</h6>

                            </AccordionItem>

                            <AccordionItem title="Knee arthroplasty, suspected subluxation, dislocation, component loosening, osteonecrosis, impingement, periprosthetic fracture, clinical concern for instability, osteolysis, infection.">
                                <h6>Radiographs initial imaging </h6>
                                <p className = {styles.projection}>
                                    AP & Lateral

                                    Skyline for suspected maltracking

                                </p>
                                <p className = {styles.comment}>

                                </p>
                            </AccordionItem>

                            <AccordionItem title="Knee arthroplasty, suspected quadriceps/patellar tendinopathy, periprosthetic soft tissue abnormality unrelated to infection, post-operative arthrofibrosis, patellar clunk syndrome impingement of nerves or other soft tissues.">
                                <h6>Ultrasound or MRI</h6>

                            </AccordionItem>

                            <AccordionItem title="Ankle pain, acute, posttraumatic, patient meets the requirements by the Ottawa Ankle Rules which are positive: 1. Inability to bear weight immediately after injury OR 2. Point tenderness over the medial malleolus, posterior edge or inferior tip of the lateral malleolus, talus or calcaneus OR 3. Inability to ambulate for 4 steps in the x-ray department.">
                                <h6>Radiography</h6>
                                <p className = {styles.projection}>
                                    AP mortice and Lateral

                                    AP in addition for trauma

                                </p>

                            </AccordionItem>

                            <AccordionItem title="Ankle pain, acute trauma, patient does not meet the Ottawa Ankle rules, however, neurologic disorders or neuropathy or other is present">
                                <h6>Radiography</h6>
                                <p className = {styles.projection}>
                                    AP mortice and Lateral

                                    AP in addition for trauma
                                </p>

                            </AccordionItem>

                            <AccordionItem title="Acute or chronic ankle pain, suspect ligamentous injury">
                                <h6>Ultrasound</h6>

                            </AccordionItem>

                            <AccordionItem title="Acute foot pain, suspect fracture or dislocation">
                                <h6>Radiography</h6>
                                <p className = {styles.projection}>
                                    AP and Oblique,

                                    Lateral in addition for trauma

                                </p>

                            </AccordionItem>

                            <AccordionItem title="Chronic foot pain, hallux valgus, DJD, painful accessory ossicle, Freiberg’s infraction, persistent posttraumatic foot pain, suspect complex regional pain syndrome, diabetic foot.">
                                <h6>Radiography</h6>
                                <p className = {styles.projection}>
                                    AP & Oblique Weight bearing
                                </p>
                                <p className = {styles.comment}>
                                    If radiographs are negative or equivocal MRI or ultrasound may be helpful
                                </p>
                            </AccordionItem>

                            <AccordionItem title="Chronic foot pain, concern for Morton’s neuroma, intermetatarsal bursitis, sesamoiditis, painful accessory ossicle, entrapment syndromes, tendon abnormalities.">

                            </AccordionItem>

                            <AccordionItem title="Chronic foot pain, suspect occult fracture or painful accessory ossicle">
                                <h6>MRI</h6>

                            </AccordionItem>

                            <AccordionItem title="Heel pain, suspect plantar faschiosis">
                                <h6>Ultrasound First –
                                    X-ray mat be useful as a follow up examination
                                </h6>

                            </AccordionItem>

                            <AccordionItem title="Chronic extremity joint pain, suspected inflammatory arthritis: rheumatoid, erosive OA, seronegative spondyloarthropathy involving the extremities, gout, CPPD.">
                                <h6>Radiography</h6>

                            </AccordionItem>
                        </Accordion>



                    </Tab>
                    <Tab id="tab-3" label="Upper Limb" >
                        <Accordion>
                        <AccordionItem title="Shoulder pain, atraumatic, suspect rotator cuff disorders, tendinosis, tear, calcific tendinitis, instability, impingement, bursitis, adhesive capsulitis, biceps tendinitis/dislocation/tear. ">
                            <h6>Ultrasound</h6>
                            <p className = {styles.projection}>

                            </p>
                            <p className = {styles.comment}>
                                Pain after rotator cuff repair: radiographs may be initial imaging then proceed to ultrasound if equivocal or negative findings.
                            </p>
                        </AccordionItem>

                        <AccordionItem title="Shoulder pain, atraumatic, suspect labral tear">
                            <h6>MR arthrography</h6>

                        </AccordionItem>

                        <AccordionItem title="Shoulder pain, acute, post-traumatic, suspect fracture or dislocation">
                            <h6>Radiography</h6>
                            <p className = {styles.projection}>
                                AP & Axial
                            </p>
                            <p className = {styles.comment}>
                                Y view should be included if clinical suspicion of dislocation.
                            </p>
                        </AccordionItem>

                        <AccordionItem title="Elbow pain, acute, suspect fracture or dislocation">
                            <h6>Radiography</h6>
                            <p className = {styles.projection}>
                                AP & Lateral
                            </p>

                        </AccordionItem>

                        <AccordionItem title="Elbow pain, chronic, mechanical symptoms, clicking, locking limited motion,  suspect osteochondral body, osteophytosis, heterotopic ossification, inflammatory arthritis.">
                            <h6>Radiography</h6>
                            <p className = {styles.projection}>
                                AP and Lateral
                            </p>

                        </AccordionItem>

                        <AccordionItem title="Elbow pain, chronic, suspect nerve abnormality, biceps tendon tear, collateral ligament tear, epicondylitis, palpable soft tissue mass, synovial abnormality.">
                            <h6>Ultrasound</h6>

                        </AccordionItem>

                        <AccordionItem title="Elbow pain, chronic, suspect intra-articular osseous body, osteochondral defect, occult fracture, assess stability of osteochondral injury. ">
                            <h6>MRI</h6>

                        </AccordionItem>

                        <AccordionItem title="Wrist and hand, acute, traumatic.">
                            <h6>Radiography</h6>
                            <p className = {styles.projection}>
                                DP & Lateral

                                With additional oblique in trauma

                            </p>
                            <p className = {styles.comment}>
                                If negative or equivocal proceed to MRI.
                            </p>
                        </AccordionItem>

                        <AccordionItem title="Acute wrist or hand fracture, penetrating trauma with foreign body, or MCP, PIP or DIP malalignment on radiographs with suspected tendon or ligament trauma">
                            <h6>Ultrasound</h6>
                            <p className = {styles.projection}>

                            </p>
                            <p className = {styles.comment}>

                            </p>
                        </AccordionItem>

                        <AccordionItem title="Wrist  pain, chronic, posttraumatic or atraumatic, suspect necrosis or non-union of the scaphoid or other carpal bones">
                            <h6>Radiographs initial imaging.</h6>
                            <p className = {styles.projection}>
                                DP, lateral  & oblique
                            </p>
                            <p className = {styles.comment}>
                                If negative or equivocal proceed to MRI
                            </p>
                        </AccordionItem>
                        </Accordion>
                    </Tab>
                    <Tab
                        id="tab-4"
                        label="Chest"
                        >
<Accordion>
    <AccordionItem title="Chest illness, suspected pneumonia, lung carcinoma (Pancoast tumour), pleural effusion, pneumothorax, sternal fracture, thoracic outlet syndrome.">
        <h6>Radiography initial imaging</h6>
        <p className = {styles.projection}>
            PA

            In case of pneumothorax Insp and Exp
        </p>
        <p className = {styles.comment}>
            Sternal fracture and pleural effusion should include a lateral film.
        </p>
    </AccordionItem>

    <AccordionItem title="Rib fractures">
        <h6>Ultrasound</h6>

    </AccordionItem>

</Accordion>





                    </Tab>
                    <Tab
                        id="tab-5"
                        label="Vascular"
                    >
                        <Accordion>

                        <AccordionItem title="Abdominal aortic aneurysm">
                            <h6>Ultrasound</h6>
                            <p className = {styles.projection}>

                            </p>
                            <p className = {styles.comment}>
                                Surveillance and initial evaluation.
                            </p>
                        </AccordionItem>
                        <AccordionItem title="Carotid artery bruit">
                            <h6>Ultrasound</h6>

                        </AccordionItem>
                        </Accordion>
                    </Tab>
                    <Tab
                        id="tab-6"
                        label="Urologic"
                    >
                        <Accordion>
                        <AccordionItem title="Acute low back pain with flank pain, chronic low back pain and flank pain, suspect urolithiasis.">
                            <h6>Ultrasound initial imaging.</h6>

                        </AccordionItem>
                        </Accordion>
                    </Tab>
                    <Tab
                        id="tab-7"
                        label="Paediatrics"
                    >
                        <Accordion>
                        <AccordionItem title="Spine, low back pain, overuse. Suspect pars fracture or active pars oedema.">
                            <h6>MRI, preferable initial imaging. </h6>
                            <p className = {styles.projection}>
                                Lateral
                            </p>
                            <p className = {styles.comment}>
                                Radiography: lateral only.
                            </p>
                        </AccordionItem>
                        <AccordionItem title="Spine trauma, posttraumatic torticollis">
                            <h6>Radiography </h6>
                            <p className = {styles.projection}>
                                AP and Lateral
                            </p>
                            <p className = {styles.comment}>
                                Refer to radiologist.
                            </p>
                        </AccordionItem>

                        <AccordionItem title="Spinal dysraphism">
                            <h6>Radiography initial imaging.</h6>

                        </AccordionItem>
                        <AccordionItem title="Radiopaque foreign body ? Ingested ">
                            <h6>Radiography</h6>

                            <p className = {styles.comment}>
                                Ultrasound for appendicular
                            </p>
                        </AccordionItem>
                        <AccordionItem title="Hip, developmental dysplasia of the hip">
                            <h6>Ultrasound &lt; 1 yoa. Radiography &gt; 1 yoa</h6>

                            <p className = {styles.comment}>
                                AP pelvis
                            </p>
                        </AccordionItem>
                        <AccordionItem title="Avascular necrosis of the hip, Perthes disease">
                            <h6>Radiography</h6>

                            <p className = {styles.comment}>
                                AP pelvis and lateral of symptomatic hip
                            </p>
                        </AccordionItem>
                        <AccordionItem title="Slipped upper femoral epiphysis">
                            <h6>Radiography</h6>

                            <p className = {styles.comment}>
                                Bilateral lateral only
                            </p>
                        </AccordionItem>
                        <AccordionItem title="Transient synovitis of the hip">
                            <h6>MRI</h6>

                            <p className = {styles.comment}>
                                Indicated if no improvement with care. Ultrasound may be helpful.
                            </p>
                        </AccordionItem>
                            <AccordionItem title="Scoliosis and painful scoliosis">
                        <h6>Radiography initial imaging</h6>

                        <p className = {styles.comment}>
                            EOS. MRI additional study if left thoracic curvature. Painful scoliosis: ABC, Osteoid osteoma, Osteoblastoma
                        </p>
                    </AccordionItem>
                            <AccordionItem title="Upper and lower limb trauma, suspect fracture or dislocation">
                        <h6>Radiography</h6>

                    </AccordionItem>
                        <AccordionItem title="Lower limb conditions: Osgood-Schlatter’s disease, Sever’s disease">
                            <h6>Radiography</h6>

                        </AccordionItem>

                            <AccordionItem title="Bone tumours">
                                <h6>Radiography initial imaging</h6>
                                <p className = {styles.comment}>
                                    Osteochondroma, aneurysmal bone cyst, osteoid osteoma, osteoblastoma, simple bone cyst, osteosarcoma, Ewing sarcoma. Clinical indicators of bone pain, swelling, prodromal pain, pain not getting better with treatment.
                                </p>
                            </AccordionItem>

                        </Accordion>

                    </Tab>


                </Tabs>



            </div>

        );
    }
}
