import styles from '../styles/referral.module.scss'
import React, {Component} from "react";


export default class About extends Component {


    render() {

        return (


      <div>
                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>AECC university college</h3>
                </div>


                <main className={styles.main}>
                    <fieldset className={styles.formSection}>
                        <legend>About us</legend>


                    </fieldset>

                    <fieldset className={styles.formSection}>
                        <legend>Data Policies</legend>
                        <p>This site uses <em>Strictly necessary cookies</em> in order to function.</p>
                        <p>These are used to:</p>
                        <ul>
                            <li>Provide a login session to keep you signed in.</li>
                        </ul>

                        <p>These cookies and session objects are automatically removed when you close your browser window, or when you log out.</p>
                    </fieldset>

                </main>
      </div>


        )
    }

}

