import styles from '../../styles/referral.module.scss'
import React, {Component} from "react";
import { Field, Form, Formik} from "formik";

import PatientData, {patientDataInitialValues, patientDataValidationSchema} from "../../component/subform/PatientData";
import PhysioData, {physioDataInitialValues, physioDataValidationSchema} from "../../component/subform/PhysioData";
import JustificationData, {justificationDataInitialValues, justificationDataValidationSchema} from "../../component/subform/JustificationData";


import MessageDisplay from "../../component/MessageDisplay";
import AuthService from "../../util/AuthService";
import {Redirect} from "react-router-dom";
import ReferralService from "../../util/ReferralService";
import {Button, ButtonSet} from "carbon-components-react";
import ActionButton from "../../component/ActionButton";


/*
To avoid re-render issues with our components specifying their formik
values at render time, we're cheating and specifying them here with
an import from the relevant class definition
 */

const initialValues = {
    ...patientDataInitialValues,
    ...physioDataInitialValues,
    ...justificationDataInitialValues,
};

const validationSchema = patientDataValidationSchema
    .concat(physioDataValidationSchema)
    .concat(justificationDataValidationSchema);

const postTarget = "/physio";

export default class Physio extends Component {


    constructor(props)
    {
        super(props);

        this.state = {
            message: "",
            redirectTo: "",
            submitting: false
        };
    }

    componentDidMount()
    {
        const user = AuthService.getCurrentUser();

        //If the session has expired or the visitor has landed here direct before login, send them to the home
        //page
        if (!user) this.setState({redirectTo: "/home"});

        this.setState({user: user, ready: true})
    }

    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }

        return (
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        //double click
                        if (!this.state.submitting) {

                            this.setState({
                                submitting: true,
                                message: ""
                            })
                            ReferralService.submit(postTarget, values).then(
                                () => {
                                    this.setState({
                                        submitting: false,
                                        message: "success"
                                    })
                                    document.getElementById("messageBox").scrollIntoView();
                                    this.props.history.push("/success");

                                },
                                error => {
                                    const resMessage =
                                        (error.response &&
                                            error.response.data &&
                                            error.response.data.message) ||
                                        error.message ||
                                        error.toString();

                                    this.setState({
                                        submitting: false,
                                        message: resMessage
                                    });
                                    document.getElementById("messageBox").scrollIntoView();
                                }
                            );
                        }
                    }}
                >


                        <Form>

                            <Field id="imagingType" className={styles.hiddenInput} name="imagingType" value="xray"/>

                            <div className={styles.pageTitleContainer}>
                                <h3 className={styles.pageTitle}>Physiotherapy referral request</h3>
                            </div>

                            <MessageDisplay message={this.state.message}/>

                            <PatientData/>

                            <PhysioData/>

                            <JustificationData/>


                            <ButtonSet className={styles.formControlsRight}>
                                <Button type = "reset" kind = "secondary" >Clear form</Button>
                                <ActionButton  hasActivity = {this.state.submitting} backgroundColour = "#ffffff">Make request</ActionButton>
                            </ButtonSet>

                        </Form>

                </Formik>
            </div>
        )
    }
}