import styles from "../../styles/referral.module.scss";
import {ErrorMessage, FastField, Field, FormikContext} from "formik";
import DatePickerField from "../DatePickerField";
import React, {Component} from "react";
import * as Yup from "yup";


export const patientDataValidationSchema = Yup.object().shape({
    patientName: Yup.string().trim().required('We need to know the patient\'s name.'),
    patientDOB: Yup.string().trim().required('We need to know the patient\'s Date of Birth.'),
    patientAddress: Yup.string().trim().required('We need to know the patient\'s address.'),
    patientGender: Yup.string().trim().required('We need to know the patient\'s gender.').matches(/^(male)$|^(female)$/, 'regex We need to know the patient\'s gender.'),
    patientPostCode: Yup.string().trim().required('We need to know the patient\'s Post Code.'),
    patientTelephone: Yup.string().trim().required("We need to have a contact number for the patient."),
    patientNHSNumber: Yup.string().trim(),
});

export const patientDataInitialValues = {
    patientDOB: new Date(),
    patientName: "",
    patientGender: "",
    patientAddress: "",
    patientPostCode: "",
    patientTelephone: "",
    patientGenderChanged: "",
    patientNHSNumber: "",
}


export default class PatientData extends Component {


    static contextType=FormikContext;

    render() {

        const {errors, touched} = this.context;


        return (
            <fieldset className={styles.formSection}>
                <legend>Patient data</legend>

                <div className={styles.formRow}>

                    <div className={`${styles.question} ${styles.questionGrow}`}>
                        <label className={styles.defaultLabel} htmlFor="patientName">Name</label>


                        <FastField name="patientName"
                               className={errors.patientName && touched.patientName ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                               autoComplete="off"/>
                        <ErrorMessage className={styles.validationWarning} component="div" name={"patientName"}/>
                    </div>

                    <div
                        className={`${styles.question} ${styles.questionGrow}`}
                        >
                        <label className={styles.defaultLabel} htmlFor="patientDOB">Date Of birth</label>



                        <FastField id="patientDOB" name="patientDOB" component={DatePickerField}
                                   className={errors.patientDOB && touched.patientDOB ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                   autoComplete="off"
                        />
                            <ErrorMessage className={styles.validationWarning} component="div" name={"patientDOB"}/>
                    </div>

                    <div className={`${styles.question} ${styles.questionFixed}`}>

                        <label className={styles.defaultLabel} htmlFor="patientGender">Gender</label>
                        <div className={styles.radioGroupButtonSurround}>
                            <div className={styles.inputButtonElementSurround}>
                                <Field id="patientGenderFemale" name="patientGender" type="radio"
                                       className={styles.inputRadio}
                                       value="female"
                                       autoComplete="off"
                                />

                                <label htmlFor="patientGenderFemale">Female</label>
                            </div>
                            <div className={styles.inputButtonElementSurround}>
                                <Field id="patientGenderMale" name="patientGender" type="radio"
                                       className={styles.inputRadio} value="male"
                                       autoComplete="off"
                                />

                                <label htmlFor="patientGenderMale">Male</label>
                            </div>
                            <ErrorMessage className={styles.validationWarning} component="div" name={"patientGender"}/>
                        </div>
                    </div>
                </div>

                <div className={styles.formRow}>

                    <div className={`${styles.question} ${styles.question}`}>
                        <label className={styles.defaultLabel} htmlFor="patientAddress">Address</label>
                        <FastField as="textarea"
                               name="patientAddress"
                               className={errors.patientAddress && touched.patientAddress ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                               autoComplete="off"

                        />
                        <ErrorMessage className={styles.validationWarning} component="div" name={"patientAddress"}/>

                    </div>

                    <div className={`${styles.question} ${styles.questionFixed}`}>
                        <label className={styles.defaultLabel} htmlFor="patientGenderChanged">Has gender changed since birth?</label>
                        <div className={styles.radioGroupButtonSurround}>
                            <div className={styles.inputButtonElementSurround}>
                                <Field name="patientGenderChanged"
                                       id="genderChangedQuestion"
                                           type="checkbox"
                                           autoComplete="off"
                                           className={styles.inputRadio}
                                />
                                <label htmlFor="genderChangedQuestion">Yes</label>
                            </div>
                        </div>
                        <ErrorMessage className={styles.validationWarning} component="div" name={"patientGenderChanged"}/>
                    </div>



                </div>

                <div className={styles.formRow}>
                    <div className={`${styles.question} ${styles.questionFixed}`}>
                        <label className={styles.defaultLabel} htmlFor="patientPostCode">Post code</label>
                        <FastField name="patientPostCode"
                               autoComplete="off"
                               className={errors.patientPostCode && touched.patientPostCode ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                        />
                        <ErrorMessage className={styles.validationWarning} component="div" name={"patientPostCode"}/>
                    </div>

                    <div className={`${styles.question} ${styles.questionFixed}`}>
                        <label className={styles.defaultLabel} htmlFor="patientTelephone">Telephone</label>
                        <FastField name="patientTelephone"
                               autoComplete="off"
                               className={errors.patientTelephone && touched.patientTelephone ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                        />
                        <ErrorMessage className={styles.validationWarning} component="div" name={"patientTelephone"}/>
                    </div>

                    <div className={`${styles.question} ${styles.questionGrow}`}>
                        <label className={styles.defaultLabel} htmlFor="patientNHSNumber">NHS number</label>
                        <FastField name="patientNHSNumber"
                                   autoComplete="off"
                                   className={errors.patientNHSNumber && touched.patientNHSNumber ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                        />
                        <ErrorMessage className={styles.validationWarning} component="div" name={"patientNHSNumber"}/>
                    </div>


                </div>







            </fieldset>
        )

    }
}