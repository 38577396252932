import styles from "../../styles/referral.module.scss";
import {ErrorMessage, Field, useFormikContext} from "formik";
import React from "react";
import * as Yup from "yup";



export const justificationDataInitialValues = {
    justification: "",
}

export const justificationDataValidationSchema = Yup.object().shape({
    justification: Yup.string().trim().required('What\'s the purpose of the imaging request.'),
});




export default function JustificationData(props) {

    const { errors, touched } = useFormikContext();

    return (
        <fieldset className={styles.formSection}>
            <legend>Clinical justification</legend>

            <div className={styles.formRow}>
                <div className={styles.question}>
                    <label className={styles.defaultLabel} htmlFor="justification">Notes </label>
                    <Field as="textarea"
                           autoComplete="off"
                           name="justification"
                           className={errors.justification && touched.justification ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                    />
                    <ErrorMessage className={styles.validationWarning} component="div" name={"justification"}/>
                </div>
            </div>


        </fieldset>

    )
}