import React, {Component} from "react";

import AuthService from "../util/AuthService";

import styles from "../styles/referral.module.scss";
import TimeSpinner from "../component/TimeSpinner";
import {Redirect} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik} from "formik";

import * as Yup from "yup";
import MessageDisplay from "../component/MessageDisplay";
import {Button, ButtonSet} from "carbon-components-react";
import ActionButton from "../component/ActionButton";


const RegisterSchema = Yup.object().shape({
    email: Yup.string().trim().email('Invalid email address').required('Please enter a valid email address'),
    displayName: Yup.string().trim().min(3, 'Names should be a minimum of 3 characters').max(50, 'Names should be a maximum of 50 characters').required('Please ensure you enter a name'),
    practiceName: Yup.string().trim().required('Please enter the name of your practice'),
    address: Yup.string().trim().required('Please enter a contact address'),
    password: Yup.string().trim().min(8, "Passwords should be a minimum of 8 characters"),
    professionalBody: Yup.string().trim().required('Please select a body'),
    registrationNumber: Yup.string().trim()
        .when('professionalBody', {
            is: 'Other',
            then: Yup.string().required('Please Describe your affiliation'),
            otherwise: Yup.string().required('Please enter your registration number'),
        })
});

const validator = values => {

    if (values.password === values.password2) {
        return {}
    } else {
        return {
            password2: 'Your Passwords must match'
        }
    }
}

export default class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            displayName: "",
            email: "",
            profession: "",
            professionalBody: "",
            registrationNumber: "",
            address: "",
            poNumber: "",
            practiceName: "",

            message: "",
            redirectTo: "",
            loading: true,
            submitting: false
        };
    }

    componentDidMount() {

        AuthService.getProfile().then(
            response => {
                this.setState({
                    loading: false,
                    displayName: response.data.displayName,
                    email: response.data.email,
                    profession: response.data.profession,
                    professionalBody: response.data.professionalBody,
                    registrationNumber: response.data.registrationNumber,
                    address: response.data.address,
                    poNumber: response.data.poNumber,
                    practiceName: response.data.practiceName,
                    message: ""
                })
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.setState({
                    message: resMessage
                });
                document.getElementById("messageBox").scrollIntoView();
            }
        );

    }

    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }

        const initialValues = {
            displayName: this.state.displayName,
            email: this.state.email,
            profession: this.state.profession,
            professionalBody: this.state.professionalBody,
            registrationNumber: this.state.registrationNumber,
            address: this.state.address,
            password: "",
            password2: "",
            poNumber: this.state.poNumber,
            practiceName: this.state.practiceName,
        }

        return (



            <div>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={RegisterSchema}
                        validate={validator}
                        onSubmit={async (values) => {
                            if (!this.state.submitting) {

                                this.setState({
                                    submitting: true,
                                    message: ""
                                })
                                AuthService.updateProfile(values).then(
                                    () => {
                                        this.setState({
                                            submitting: false,
                                            message: "Your profile has been updated"
                                        })
                                        document.getElementById("messageBox").scrollIntoView();
                                        this.props.history.push("/success");

                                    },
                                    error => {
                                        const resMessage =
                                            (error.response &&
                                                error.response.data &&
                                                error.response.data.message) ||
                                            error.message ||
                                            error.toString();

                                        this.setState({
                                            submitting: false,
                                            message: resMessage
                                        });
                                        document.getElementById("messageBox").scrollIntoView();

                                    }
                                );

                            }
                        }
                        }

                    >
                        {({errors,values,  touched}) => (




                            <Form>


                                <div className={styles.pageTitleContainer}>
                                    <h3 className={styles.pageTitle}>Your Profile</h3>
                                </div>


                                <MessageDisplay message={this.state.message} />

                                {this.state.loading ? (
                                    <fieldset className={styles.formSection}>
                                        <legend>Retrieving Profile</legend>


                                        <span>
                                        <TimeSpinner />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ...Please wait - We are retrieving your profile information
                                    </span>

                                    </fieldset>
                                ) : (

                                    <div>
                                <MessageDisplay message={this.state.message} />

                                <fieldset className={styles.formSection}>
                                    <legend>About You</legend>

                                    <div className={styles.formRow}>

                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="displayName">Your Name</label>
                                            <Field name="displayName"
                                                   className={errors.displayName && touched.displayName ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div"
                                                          name={"displayName"}/>
                                        </div>
                                    </div>

                                    <div className={styles.formRow}>
                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="email">Your Email Address</label>
                                            <Field name="email"
                                                   className={errors.email && touched.email ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"email"}/>
                                        </div>

                                    </div>

                                    <div className={styles.formRow}>
                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="profession">Profession/Speciality</label>
                                            <Field name="profession"
                                                   className={errors.profession && touched.profession ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"profession"}/>
                                        </div>

                                    </div>

                                    <div className={styles.formRow}>
                                        <div className={`${styles.question} ${styles.questionFixed}`}>
                                            <label className={styles.defaultLabel} htmlFor="professionalBody">Professional Body
                                            </label>
                                            <Field name="professionalBody"
                                                   as="select"
                                                   className={errors.professionalBody && touched.professionalBody ? `${styles.validateWarn} ${styles.defaultInput} ${styles.selectInput}` : `${styles.defaultInput} ${styles.selectInput}`}
                                                   autoComplete="off">
                                                <option value="">&nbsp;</option>
                                                <option value="GMC">GMC</option>
                                                <option value="HCPC">HCPC</option>
                                                <option value="NMC">NMC</option>
                                                <option value="GCC">GCC</option>
                                                <option value="Other">Other</option>
                                            </Field>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"professionalBody"}/>
                                        </div>
                                        <div className={`${styles.question} ${styles.questionGrow}`}>



                                            { values.professionalBody==="Other" ? (
                                                <label className={styles.defaultLabel} htmlFor="registrationNumber">Please describe your affiliation.</label>



                                            ) : (
                                                <label className={styles.defaultLabel} htmlFor="registrationNumber">Registration
                                                    Number with Body</label>

                                            )}

                                            <Field name="registrationNumber"
                                                   className={errors.registrationNumber && touched.registrationNumber ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"registrationNumber"}/>
                                        </div>


                                    </div>


                                </fieldset>
                                <fieldset className={styles.formSection}>
                                    <legend>Your Practice</legend>


                                    <div className={styles.formRow}>

                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="practiceName">Practice
                                                Name/Hospital</label>
                                            <Field name="practiceName"
                                                   className={errors.practiceName && touched.practiceName ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div"
                                                          name={"practiceName"}/>
                                        </div>
                                    </div>
                                    <div className={styles.formRow}>

                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="address">Address</label>
                                            <Field name="address"
                                                   as="textarea"
                                                   className={errors.address && touched.address ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"address"}/>
                                        </div>
                                    </div>

                                    <div className={styles.formRow}>
                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="poNumber">If you're NHS funded please enter
                                                your PO Number</label>
                                            <Field name="poNumber"
                                                   className={errors.poNumber && touched.poNumber ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"poNumber"}/>
                                        </div>

                                    </div>

                                </fieldset>

                                        <fieldset className={styles.formSection}>
                                            <legend>Enabled Services</legend>


                                            <div>

                                                {this.props.parentState.showXRay && (
                                                    <p>Xray</p>
                                                )}
                                                {this.props.parentState.showMRI && (
                                                    <p>MRI</p>
                                                )}
                                                {this.props.parentState.showUltrasound && (
                                                    <p>Ultrasound</p>
                                                )}
                                                {this.props.parentState.showChiro && (
                                                    <p>Chiro</p>
                                                )}
                                                {this.props.parentState.showPhysio && (
                                                    <p>Physio</p>
                                                )}
                                                {this.props.parentState.showBasic && (
                                                    <p>Basic</p>
                                                )}




                                            </div>

                                        </fieldset>

                                <p>If you do not wish to update your password please leave the following fields blank whilst updating your details. Your password will remain unchanged</p>

                                <fieldset className={styles.formSection}>
                                    <legend>Authentication</legend>

                                    <div className={styles.formRow}>

                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="password">Pick a password</label>
                                            <Field name="password"
                                                   type="password"
                                                   className={errors.password && touched.password ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"password"}/>
                                        </div>
                                    </div>
                                    <div className={styles.formRow}>

                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="password2">Enter Your
                                                Password again for confirmation</label>
                                            <Field name="password2"
                                                   type="password"
                                                   className={errors.password2 && touched.password2 ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"password2"}/>
                                        </div>


                                    </div>
                                </fieldset>

                                        <ButtonSet className={styles.formControlsRight}>

                                            <Button type = "reset" kind = "secondary" >Reset form</Button>
                                            <ActionButton hasActivity = {this.state.submitting} backgroundColour = "#ffffff">Make changes</ActionButton>
                                        </ButtonSet>

                                    </div>
                                    )}
                            </Form>
                        )}
                    </Formik>
















            </div>
        );
    }
}
