import React, {Component} from "react";
import AuthService from "../util/AuthService";

import styles from "../styles/referral.module.scss";
import {Link, Redirect} from "react-router-dom";





import {Button, ButtonSet} from "carbon-components-react";





export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            redirectTo: "",
            message: ""
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if (user) {
            this.setState({
                user: user,
                showBasic: user.roles.includes("ROLE_BASIC"),
                showMRI: user.roles.includes("ROLE_MRI"),
                showXRay: user.roles.includes("ROLE_XRAY"),
                showUltrasound: user.roles.includes("ROLE_ULTRASOUND"),
                showPhysio: user.roles.includes("ROLE_PHYSIO"),
                showChiro: user.roles.includes("ROLE_CHIRO"),
                isStudent: user.roles.includes("ROLE_STUDENT"),
            });
        }

        this.setState({
            user: user,
            ready: true
        })

    }


    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }


        return (
            <div>
                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Welcome to the AECC university college diagnostics portal</h3>
                </div>

                {this.state.message.length > 1 && (
                    <fieldset className={styles.serverMessage}>
                        <p>{this.state.message}</p>
                    </fieldset>
                )}

                {(this.state.user && this.state.ready) ? (
                    <div>


                        <p className={styles.instruction}>
                            Please consult our <Link to = "/guidelines">referral criteria guidelines</Link> and select the service you are interested in:
                        </p>


                        <ButtonSet id = "serviceOptions">

                            {this.props.parentState.showXRay ? (
                            <Link to = "/referral/x-ray" className = "bx--btn--surround">
                                <Button as = "div" role = "button" kind="primary" size="md" type="button" tabIndex="10" >X-Ray</Button>
                            </Link>
                            ) : (

                                <Button className = "bx--btn--surround" disabled="true" size = "md" kind="primary">X-Ray</Button>

                            )}

                            {this.props.parentState.showMRI ? (
                                <Link to="/referral/mri" className = "bx--btn--surround">
                                    <Button as = "div" role = "button" kind="primary" size="md" type="button" tabIndex="20">MRI</Button>
                                 </Link>
                            ) : (
                                <Button className = "bx--btn--surround" disabled="true" size = "md" kind="primary">MRI</Button>
                            )}




                            {this.props.parentState.showUltrasound ? (
                                <Link to="/referral/ultrasound" className = "bx--btn--surround">
                                    <Button as = "div" role = "button" kind="primary" size="md" type="button" tabIndex="30">Ultrasound</Button>

                                </Link>
                            ) : (
                                <Button className = "bx--btn--surround" disabled="true" size = "md" kind="primary">Ultrasound</Button>
                            )}

                            {this.props.parentState.showPhysio ? (
                                <Link to="/referral/physio" className = "bx--btn--surround">
                                    <Button as = "div" role = "button" kind="primary" size="md" type="button" tabIndex="40" >Physio</Button>
                                </Link>
                            ) : (
                                <Button className = "bx--btn--surround" disabled="true" size = "md" kind="primary">Physio</Button>
                            )}

                            {this.props.parentState.showChiro ? (

                                    <Link to="/referral/chiro" className = "bx--btn--surround">
                                        <Button as = "div" role = "button" kind="primary" size="md" type="button" tabIndex="50">Chiro</Button>

                                    </Link>

                            ) : (
                                <Button className = "bx--btn--surround" disabled="true" size = "md" kind="primary">Chiro</Button>

                            )}

                        </ButtonSet>
                        <p className={styles.instruction}>or</p>


                        <div id = "secondaryOptions">
<Link to="/logout" className = "bx--btn--surround">
                                <Button as = "div" role = "button" kind="secondary" size="md" type="button" tabIndex="60" className="bx--btn--wide">Logout</Button>

                            </Link>
                    </div>

                        <p>Please Note: Some referral services require verification before being enabled. If you're missing services please ensure your profile information is up to date and contact us on 01202 436276</p>
                    </div>

                ) : (

                    <div>
                        <p >
                            AECC University College offers a number of diagnostic services to accredited professionals and other practitioners. To make use of our services and gain access to this referrals portal please register for an account.
                        </p>

                        <p className={styles.instruction}>
                            The academic staff chiropractic clinic provides clinical chiropractic and imaging services from our
                            fully qualified practitioners who teach at AECC university college. Many of our academic
                            staff have additional postgraduate qualifications and are considered experts in their
                            particular fields.
                        </p>


                        <ButtonSet>
                            <Link to = "/register" className = "bx--btn--surround">
                                <Button as = "div" role = "button" kind="secondary" tabIndex="20" size="md" type="button" className="bx--btn--wide">
                                    Register
                                </Button>
                            </Link>
                            <Link to = "/login" className = "bx--btn--surround">
                                <Button as = "div" role = "button" kind="primary" tabIndex="10" size="md" type="button" className="bx--btn--wide">
                                    Login
                                </Button>
                            </Link>
                        </ButtonSet>




                    </div>
                )}
            </div>
        );
    }
}
