import styles from "../../styles/referral.module.scss";
import {ErrorMessage, Field, useFormikContext} from "formik";
import React from "react";
import * as Yup from "yup";

export const physioDataInitialValues = {
    examination: ""
}

export const physioDataValidationSchema = Yup.object().shape({
    examination: Yup.string().trim().required('Please describe the investigation required.')
});


export default function PhysioData(props) {

    const { errors, touched } = useFormikContext();

    return (
        <fieldset className={styles.formSection}>
            <legend>Requested Activities</legend>

            <div className={styles.formRow}>
                <div className={styles.question}>
                    <label className={styles.defaultLabel} htmlFor="examination">Notes</label>
                    <Field as="textarea"
                           autoComplete="off"
                           name="examination"
                           className={errors.examination && touched.examination ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                    />
                    <ErrorMessage className={styles.validationWarning} component="div" name={"examination"}/>
                </div>
            </div>

        </fieldset>

    )

}
