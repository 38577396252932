import styles from "../../styles/referral.module.scss";
import {ErrorMessage, Field, useFormikContext} from "formik";
import React from "react";
import * as Yup from "yup";



export const justificationDataInitialValues = {
    justification: "",
}

export const justificationDataValidationSchema = Yup.object().shape({
    justification: Yup.string().trim().required('What\'s the purpose of the imaging request.'),
});




export default function JustificationDataXRay(props) {

    const { errors, touched } = useFormikContext();

    return (
        <fieldset className={styles.formSection}>
            <legend>Clinical justification</legend>

            <div className={styles.formRow}>
                <div className={styles.question}>
                    <label className={styles.defaultLabel} htmlFor="justification">Notes: <span className={styles.additionalInfo}>Please include relevant clinical symptoms such as onset, type, location. Any history of trauma including the date(s). Any history of malignancy including dates and type. Please also include how the results of the x-ray will influence your clinical management.</span></label>
                    <Field as="textarea"
                           autoComplete="off"
                           name="justification"
                           className={errors.justification && touched.justification ? `${styles.validateWarn} ${styles.defaultInput} ${styles.padTop}` : `${styles.defaultInput} ${styles.padTop}`}
                    />
                    <ErrorMessage className={styles.validationWarning} component="div" name={"justification"}/>
                </div>
            </div>


        </fieldset>

    )
}