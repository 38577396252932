import axios from "axios";

const API_URL = process.env.REACT_APP_API_TARGET + "/referral";

class ReferralService {


  getAuthToken() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user && user.accessToken) {
      return user.accessToken;
    } else {
      return
    }
  }

  submit(target, values) {

    return axios.post(API_URL + target, JSON.stringify(values), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAuthToken()
      }
    } );
  }


}

export default new ReferralService();
