import React, {Component} from "react";
import {Link, Route, Switch} from "react-router-dom";
import Home from "./content/Home";
import About from "./content/About";
import Profile from "./content/Profile";
import Login from "./content/Login";
import Logout from "./content/Logout";
import Register from "./content/Register";
import XRay from "./content/referral/XRay";
import AuthService from "./util/AuthService";
import MRI from "./content/referral/MRI";
import Ultrasound from "./content/referral/Ultrasound";
import Chiro from "./content/referral/Chiro";
import Physio from "./content/referral/Physio";
import Success from "./content/Success";
import Guidelines from "./content/Guidelines";

import "./styles/aecc.default.scss";

import styles from './styles/layout.module.scss'
import SessionManager from "./util/SessionManager";






class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: undefined,
        };
    }

    componentDidMount() {
           const user = AuthService.getCurrentUser();

           if (user) {
               this.setState({
                   user: user,
                   showBasic: user.roles.includes("ROLE_BASIC"),
                   showMRI: user.roles.includes("ROLE_MRI"),
                   showXRay: user.roles.includes("ROLE_XRAY"),
                   showUltrasound: user.roles.includes("ROLE_ULTRASOUND"),
                   showPhysio: user.roles.includes("ROLE_PHYSIO"),
                   showChiro: user.roles.includes("ROLE_CHIRO"),
                   isStudent: user.roles.includes("ROLE_STUDENT")
               });
           }
    }

    render() {
        const { showBasic, showMRI, showXRay, showUltrasound, showPhysio, showChiro, isStudent } = this.state;

        return (
            <div className={styles.container}>

                <header className={styles.header}>

                    <img src="/i/AECCLogo_inverse.png"  width = "226" height="70" alt = "Aecc Logo"/>

                    <div className={styles.titleBox}>
                        <h1 className={styles.title}>
                            AECC Diagnostic services portal
                        </h1>
                    </div>
                </header>

                <div className={styles.breadcrumbContainer}>


                <div className={styles.breadcrumbLeft}>
                    { showXRay && (
                        <Link to={"/referral/x-ray"}>XRay</Link>
                    ) }

                    { showMRI && (
                        <Link to={"/referral/mri"}>MRI</Link>
                    ) }

                    { showUltrasound && (
                        <Link to={"/referral/ultrasound"}>Ultrasound</Link>
                    ) }

                    { showPhysio && (
                        <Link to={"/referral/physio"}>Physio</Link>
                    ) }

                    { showChiro && (
                        <Link to={"/referral/chiro"}>Chiro</Link>
                    ) }

                    { showBasic && (
                        <Link to={"/guidelines"}>Guidelines</Link>
                    ) }
                </div>
                <div className={styles.breadcrumbRight}>
                    <Link to={"/home"}>Home</Link>
                    <Link to={"/about"}>About</Link>

                    { showBasic && !isStudent && (
                        <Link to={"/profile"}>Profile</Link>
                    ) }

                    { showBasic ? (
                        <Link to={"/logout"} >Logout</Link>
                    ) : (
                        <Link to={"/login"}>Login</Link>
                    ) }
                </div>

                </div>
                <div className={styles.mainContainer}>

                    <div className={styles.rightPanel}>
                        <main className={styles.main}>

                            <Switch>
                                <Route exact path={["/", "/home"]} render={(props) => (
                                    <Home {...props} parentState={this.state} />
                                    ) }
                                />


                                <Route exact path={"/profile"} render={(props) => (
                                    <SessionManager Component={Profile} {...props} parentState={this.state} />
                                ) }
                                />




                                <Route exact path={["/guidelines"]}>
                                    <SessionManager Component={Guidelines} />
                                </Route>


                                <Route exact path="/register" component={Register}/>
                                <Route exact path="/about" component={About}/>
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/logout" >
                                    <Logout/>
                                </Route>

                                <Route exact path="/referral/x-ray" render={(props) => (
                                    <SessionManager Component={XRay} {...props}  />
                                ) }
                                />

                                <Route exact path="/referral/mri" render={(props) => (
                                    <SessionManager Component={MRI} {...props}  />
                                ) }
                                />

                                <Route exact path="/referral/ultrasound" render={(props) => (
                                    <SessionManager Component={Ultrasound} {...props}  />
                                ) }
                                />

                                <Route exact path="/referral/chiro" render={(props) => (
                                    <SessionManager Component={Chiro} {...props}  />
                                ) }
                                />

                                <Route exact path="/referral/physio" render={(props) => (
                                    <SessionManager Component={Physio} {...props}  />
                                ) }
                                 />


                                <Route exact path="/success" >
                                    <SessionManager Component={Success}  />
                                </Route>
                            </Switch>
                        </main>
                    </div>
                </div>
                <footer className={styles.footer}>

                </footer>
            </div>
        );
    }
}

export default App;