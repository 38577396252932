import styles from "../../styles/referral.module.scss";
import {ErrorMessage, Field, FieldArray, useFormikContext} from "formik";
import React from "react";
import * as Yup from "yup";
import {Button } from "carbon-components-react";
import {Add16, Subtract16} from "@carbon/icons-react";


export const xrayDataInitialValues = {

    films: [
        {
            area: '',
            view: '',
            useStandardViews: '',
            side: '',
        },
    ],
}

export const xrayDataValidationSchema = Yup.object().shape({
    films: Yup.array().of(
        Yup.object().shape({
            area: Yup.string().trim().required('Please indicate the area(s) required.'),
            useStandardViews: Yup.string().trim().required('Indicate view type'),
            side: Yup.string().trim(),
            view: Yup.string().trim()
                .when('useStandardViews', {
                    is: 'false',
                    then: Yup.string().required('Please indicate the view(s) required.'),
                })
        })
    ).required('Please tell us about the imaging required.').min(1, 'There must be at least 1 request.'),
});


export default function XRayData(props) {

    const {values} = useFormikContext();

    return (

        <fieldset className={styles.formSection}>
            <legend>Requested information</legend>
            <div className={styles.formRow}>
                <div className={`${styles.question} ${styles.questionFixedNarrow} ${styles.matrixHeader} `}>
                    Side
                </div>

                <div className={`${styles.question} ${styles.questionFixed} ${styles.matrixHeader} `}>
                    Body area
                </div>
                <div className={`${styles.question} ${styles.questionGrow} ${styles.matrixHeader}`}>
                    View(s) required
                </div>
                <div className={styles.questionButtons}>
                    &nbsp;
                </div>
            </div>
            <FieldArray name={"films"}>
                {({push, remove}) => (
                    <div>
                        {
                            values.films.length > 0 &&
                            values.films.map((films, index) => (
                                <div className={styles.matrixRow} key={index}>

                                    <div className={`${styles.question} ${styles.questionFixedNarrow}`}>

                                            <div className={styles.inputButtonElementSurround}>
                                                <Field id={`films[${index}].sideLeft`} name={`films[${index}].side`} type="radio"
                                                       className={styles.inputRadioChar}
                                                       value="left"
                                                       autoComplete="off"
                                                       title = "left"
                                                />

                                                <label htmlFor={`films[${index}].sideLeft`}>L</label>
                                            </div>
                                            <div className={styles.inputButtonElementSurround}>
                                                <Field id={`films[${index}].sideRight`} name={`films[${index}].side`} type="radio"
                                                       className={styles.inputRadioChar}
                                                       value="right"
                                                       autoComplete="off"
                                                       title = "right"
                                                />

                                                <label htmlFor={`films[${index}].sideRight`}>R</label>
                                            </div>
                                            <ErrorMessage className={styles.validationWarning} component="div"
                                                          name={`films[${index}].side`}/>
                                    </div>
                                        <div className={`${styles.question} ${styles.questionFixed}`}>

                                        <Field name={`films[${index}].area`}
                                               autoComplete="off"
                                               type="text"
                                               className={`${styles.matrixInput}  `}
                                        />
                                        <ErrorMessage className={styles.validationWarning} component="div"
                                                      name={`films[${index}].area`}/>
                                    </div>

                                    <div className={`${styles.question} ${styles.questionFixed} ${styles.radioAnswer}`}>

                                        <div className={styles.inputButtonSurround}>
                                            <div className={styles.inputButtonElementSurround}>
                                                <Field id={`films[${index}].useStandardViewsTrue`}
                                                       name={`films[${index}].useStandardViews`} type="radio"
                                                       className={styles.inputRadio}
                                                       value="true"
                                                       autoComplete="off"


                                                />

                                                <label htmlFor={`films[${index}].useStandardViewsTrue`}>Standard</label>
                                            </div>
                                            <div className={styles.inputButtonElementSurround}>
                                                <Field id={`films[${index}].useStandardViewsFalse`}
                                                       name={`films[${index}].useStandardViews`} type="radio"
                                                       className={styles.inputRadio}
                                                       value="false"
                                                       autoComplete="off"
                                                />

                                                <label htmlFor={`films[${index}].useStandardViewsFalse`}>Specific</label>
                                            </div>
                                            <ErrorMessage className={styles.validationWarning} component="div"
                                                          name={`films[${index}].useStandardViews`}/>
                                        </div>
                                    </div>


                                    <div
                                        className={`${styles.question} ${styles.questionGrow} ${styles.reducedLeftMargin}`}>

                                        {values.films[index].useStandardViews === "false" ? (

                                            <div>
                                                <Field name={`films[${index}].view`}
                                                       type="text"
                                                       autoComplete="off"
                                                       className={`${styles.matrixInput}`}
                                                />


                                                <ErrorMessage className={styles.validationWarning} component="div"
                                                              name={`films[${index}].view`}/>
                                            </div>
                                        ) : (
                                            <span>&nbsp;</span>
                                        )
                                        }
                                    </div>
                                    <div className={styles.questionButtons}>

                                        {index >= 0 && index === values.films.length - 1 ? (
                                            <Button renderIcon={Add16}
                                                    iconDescription="Add another request"
                                                    hasIconOnly
                                                    size="sm"
                                                    tabIndex="20"
                                                    onClick={
                                                        () => {
                                                            push({films: ""});
                                                        }
                                                    }
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        {index > 0 && index === values.films.length - 1 ? (
                                            <Button renderIcon={Subtract16}
                                                    className="bx--btn--icon-only--right--button"
                                                     iconDescription="Remove request"
                                                     hasIconOnly
                                                     size="sm"
                                                     tabIndex="30"
                                                     onClick={
                                                            () => {
                                                                remove(index);
                                                            }
                                                     }
                                                />
                                        ) : (
                                            <></>
                                        )}

                                    </div>
                                </div>

                            ))
                        }
                    </div>
                )
                }
            </FieldArray>


        </fieldset>
    )
}