import React, {Component} from "react";

import AuthService from "../util/AuthService";

import styles from "../styles/referral.module.scss";
import {Redirect} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik} from "formik";

import * as Yup from "yup";
import MessageDisplay from "../component/MessageDisplay";
import {Button, ButtonSet,  SelectItem} from "carbon-components-react";
import ActionButton from "../component/ActionButton";

const initialValues = {
    displayName: "",
    email: "",
    confirmEmail: "",
    profession: "",
    professionalBody: "",
    registrationNumber: "",
    address: "",
    password: "",
    password2: "",
    poNumber: "",
    practiceName: "",
}


const RegisterSchema = Yup.object().shape({
    email: Yup.string().trim().email('Invalid email address').required('Please enter a valid email address'),
    confirmEmail: Yup.string().trim().email('Invalid email address').required('Please enter a valid email address'),
    displayName: Yup.string().trim().min(3, 'Names should be a minimum of 3 characters').max(50, 'Names should be a maximum of 50 characters').required('Please ensure you enter a name'),
    practiceName: Yup.string().trim().required('Please enter the name of your practice'),
    address: Yup.string().trim().required('Please enter a contact address'),
    password: Yup.string().trim().min(8, "Passwords should be a minimum of 8 characters").required('Please enter a Password')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,"Passwords should meet minimum complexity standards, min 8 chars, a symbol, mixed case, a number"),
    password2: Yup.string().trim().required('Please enter a Password'),
    professionalBody: Yup.string().trim().required('Please select a body'),
    registrationNumber: Yup.string().trim()
        .when('professionalBody', {
            is: 'Other',
            then: Yup.string().required('Please Describe your affiliation'),
            otherwise: Yup.string().required('Please enter your registration number'),
        })
});

/**
 * Additional validation rules, used to check verification fields match their primary counterpart
 *
 * @param values Formik values object
 * @returns {} Errors to throw in a json object for formiks error handling.
 */
const validator = values => {

    let emailCheckResponse = {};
    let passwordCheckResponse = {};

    if (values.email.toLowerCase() !== values.confirmEmail.toLowerCase()) {
        emailCheckResponse = {
            confirmEmail: "Your email addresses don't match"
        }
    }

    if (values.password !== values.password2) {
        passwordCheckResponse = {
            password2: "Your Passwords must match"
        }
    }

    return {
        ...emailCheckResponse,
        ...passwordCheckResponse
    };
}

export default class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: "",
            redirectTo: "",
            submitting: false
        };
    }

    componentDidMount() {
        //We don;t want to be using the register page if we're logged in
        const user = AuthService.getCurrentUser();


        if (user) this.setState({ redirect: "/home" });


    }

    render() {

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }





        return (



            <div>


                    <Formik
                        initialValues={initialValues}
                        validationSchema={RegisterSchema}
                        c
                        onSubmit={async (values) => {
                            this.setState({
                                submitting: true,
                                message: ""
                            })
                            AuthService.register(values).then(
                                () => {
                                    this.setState({
                                        submitting: false,
                                        message: "success"
                                    })
                                    document.getElementById("messageBox").scrollIntoView();

                                                                       this.props.history.push("/success");
                                },
                                error => {
                                    const resMessage =
                                        (error.response &&
                                            error.response.data &&
                                            error.response.data.message) ||
                                        error.message ||
                                        error.toString();

                                    this.setState({
                                        submitting: false,
                                        message: resMessage
                                    });
                                    document.getElementById("messageBox").scrollIntoView();

                                }
                            );

                        }}


                    >
                        {({errors,values,  touched}) => (




                            <Form>

                                <div className={styles.pageTitleContainer}>
                                    <h3 className={styles.pageTitle}>Register with us</h3>
                                </div>

                                <p>Please fill in the form below as completely as possible, certain services will be unavailable until we're able to verify professional registration details.
                                </p>


                                <MessageDisplay message={this.state.message} />

                                <fieldset className={styles.formSection}>
                                    <legend>About You</legend>

                                    <div className={styles.formRow}>

                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="displayName">Your name</label>
                                            <Field name="displayName"
                                                   className={errors.displayName && touched.displayName ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div"
                                                          name={"displayName"}/>
                                        </div>
                                    </div>

                                    <div className={styles.formRow}>
                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="email">Your email address</label>
                                            <Field name="email"
                                                   className={errors.email && touched.email ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"email"}/>
                                        </div>

                                    </div>

                                    <div className={styles.formRow}>
                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="email">Confirm your email address</label>
                                            <Field name="confirmEmail"
                                                   className={errors.confirmEmail && touched.confirmEmail ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"confirmEmail"}/>
                                        </div>

                                    </div>


                                    <div className={styles.formRow}>
                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="profession">Profession/Speciality</label>
                                            <Field name="profession"
                                                   className={errors.profession && touched.profession ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"profession"}/>
                                        </div>

                                    </div>

                                    <div className={styles.formRow}>
                                        <div className={`${styles.question} ${styles.questionFixed}`}>
                                            <label className={styles.defaultLabel} htmlFor="professionalBody">Professional body
                                            </label>
                                            <Field
                                                name="professionalBody"
                                                   as="select"
                                                   //as={Select}
                                                   hideLabel="true"
                                                   light="true"
                                                   inline="true"
                                                   className={errors.professionalBody && touched.professionalBody ? `${styles.validateWarn} ${styles.defaultInput} ${styles.selectInput}` : `${styles.defaultInput} ${styles.selectInput}`}
                                                   autoComplete="off">
                                                <SelectItem hidden text="choose an option" />
                                                <SelectItem value="GMC" text="GMC"/>
                                                <SelectItem value="HCPC" text = "HCPC"/>
                                                <SelectItem value="NMC" text = "NMC"/>
                                                <SelectItem value="GCC" text = "GCC" />
                                                <SelectItem value="Other" text="Other"/>
                                            </Field>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"professionalBody"}/>
                                        </div>
                                        <div className={`${styles.question} ${styles.questionGrow}`}>



                                            { values.professionalBody==="Other" ? (
                                                <label className={styles.defaultLabel} htmlFor="registrationNumber">Please describe your affiliation.</label>



                                            ) : (
                                                <label className={styles.defaultLabel} htmlFor="registrationNumber">Registration
                                                    number with body</label>

                                            )}

                                            <Field name="registrationNumber"
                                                   className={errors.registrationNumber && touched.registrationNumber ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"registrationNumber"}/>
                                        </div>


                                    </div>


                                </fieldset>
                                <fieldset className={styles.formSection}>
                                    <legend>Your Practice</legend>


                                    <div className={styles.formRow}>

                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="practiceName">Practice
                                                name/Hospital</label>
                                            <Field name="practiceName"
                                                   className={errors.practiceName && touched.practiceName ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div"
                                                          name={"practiceName"}/>
                                        </div>
                                    </div>
                                    <div className={styles.formRow}>

                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="address">Address</label>
                                            <Field name="address"
                                                   as="textarea"
                                                   className={errors.address && touched.address ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"address"}/>
                                        </div>
                                    </div>

                                    <div className={styles.formRow}>
                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="poNumber">If you're NHS funded please enter
                                                your PO Number</label>
                                            <Field name="poNumber"
                                                   className={errors.poNumber && touched.poNumber ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"poNumber"}/>
                                        </div>

                                    </div>

                                </fieldset>
                                <fieldset className={styles.formSection}>
                                    <legend>Authentication</legend>

                                    <div className={styles.formRow}>

                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="password">Pick a password</label>
                                            <Field name="password"
                                                   type="password"
                                                   className={errors.password && touched.password ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"password"}/>
                                        </div>
                                    </div>
                                    <div className={styles.formRow}>

                                        <div className={`${styles.question} ${styles.questionGrow}`}>
                                            <label className={styles.defaultLabel} htmlFor="password2">Enter your
                                                password again for confirmation</label>
                                            <Field name="password2"
                                                   type="password"
                                                   className={errors.password2 && touched.password2 ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                                                   autoComplete="off"/>
                                            <ErrorMessage className={styles.validationWarning} component="div" name={"password2"}/>
                                        </div>


                                    </div>
                                </fieldset>



                                    <ButtonSet className={styles.formControlsRight}>
                                        <Button type = "reset" kind = "secondary" >Clear form</Button>
                                        <ActionButton  hasActivity = {this.state.submitting} backgroundColour = "#ffffff">Make request</ActionButton>

                                </ButtonSet>

                            </Form>
                        )}
                    </Formik>
















            </div>
        );
    }
}
