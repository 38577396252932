import styles from "../../styles/referral.module.scss";
import {ErrorMessage, Field, FormikContext} from "formik";

import React, {Component} from "react";
import * as Yup from "yup";



export const mriDataInitialValues = {
    kneeLeft: "",
    kneeRight: "",
    ankleLeft: "",
    ankleRight: "",
    footLeft: "",
    footRight: "",
    shoulderLeft: "",
    shoulderRight: "",
    wristLeft: "",
    wristRight: "",
    handLeft: "",
    handRight: "",
    elbowLeft: "",
    elbowRight: "",
    brain: "",
    sacroiliacJoints: "",
    lumbarSpine: "",
    cervicalSpine: "",
    thoracicSpine: "",
    lumbarSpineWeight: "",
    lumbarSpineFlexion: "",
    cervicalSpineFlexion: "",
    thoracicSpineWeight: "",
    mriSpecialRequest: ""
}

export const mriDataValidationSchema = Yup.object().shape({

    });



export default class MriData extends Component {



    static contextType = FormikContext;

    render() {

        const {errors, touched} = this.context;

        return (

            <fieldset className={styles.formSection}>
                <legend>Investigations required</legend>
                <div className={`${styles.infoBlock} ${styles.formRow}`}>
                    Select the investigations required. Note where left side or right side investigation only is required ensure only the L or R is selected.
                    Please note investigations underlined will incur an additional cost.
                </div>

                <table className={styles.mriTable}>
                    <tbody>
                    <tr>
                        <td className={styles.mriColumn1}>Knee</td>
                        <td className={styles.mriColumn2}>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="kneeLeftId"
                                           name="kneeLeft"
                                           type="checkbox"
                                    />

                                    <label htmlFor="kneeLeftId">L</label>
                                </div>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="kneeRightId"
                                           name="kneeRight"
                                           type="checkbox"
                                    />
                                    <label htmlFor="kneeRightId">R</label>
                                </div>
                            </div>

                        </td>
                        <td className={styles.mriColumn3}>Lumbar spine</td>
                        <td className={styles.mriColumn4}>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="lumbarSpine"
                                           name="lumbarSpine"
                                           type="checkbox"
                                    />

                                    <label htmlFor="lumbarSpine">&nbsp;</label>
                                </div>

                            </div>


                        </td>

                        <td className={`${styles.mriColumn5} ${styles.cost}`}>Lumbar spine and lumbar spine weight bearing</td>
                        <td className={styles.mriColumn6}>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="lumbarSpineWeight"
                                           name="lumbarSpineWeight"
                                           type="checkbox"
                                    />

                                    <label htmlFor="lumbarSpineWeight">&nbsp;</label>
                                </div>

                            </div>

                        </td>
                        <td className={styles.mriColumn7}>Shoulder</td>
                        <td className={styles.mriColumn8}>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="shoulderLeftId"
                                           name="shoulderLeft"
                                           type="checkbox"
                                    />

                                    <label htmlFor="shoulderLeftId">L</label>
                                </div>

                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="shoulderRightId"
                                           name="shoulderRight"
                                           type="checkbox"
                                    />
                                    <label htmlFor="shoulderRightId">R</label>
                                </div>

                            </div>
                        </td>
                        <td className={styles.mriColumn9}>Brain</td>
                        <td className={styles.mriColumn10}>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="brain"
                                           name="brain"
                                           type="checkbox"

                                    />

                                    <label htmlFor="brain">&nbsp;</label>
                                </div>

                            </div>

                        </td>
                    </tr>

                    <tr>
                        <td>Ankle</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="ankleLeftId"
                                           name="ankleLeft"
                                           type="checkbox"

                                    />

                                    <label htmlFor="ankleLeftId">L</label>
                                </div>

                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="ankleRightId"
                                           name="ankleRight"
                                           type="checkbox"

                                    />
                                    <label htmlFor="ankleRightId">R</label>
                                </div>

                            </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td className={styles.cost}>Lumbar spine and lumbar spine in flexion and extension</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="lumbarSpineFlexion"
                                           name="lumbarSpineFlexion"
                                           type="checkbox"

                                    />

                                    <label htmlFor="lumbarSpineFlexion">&nbsp;</label>
                                </div>

                            </div>

                        </td>
                        <td>Wrist</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="wristLeftId"
                                           name="wristLeft"
                                           type="checkbox"

                                    />

                                    <label htmlFor="wristLeftId">L</label>
                                </div>

                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="wristRightId"
                                           name="wristRight"
                                           type="checkbox"

                                    />
                                    <label htmlFor="wristRightId">R</label>
                                </div>

                            </div>
                        </td>
                        <td>

                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Foot</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="footLeftId"
                                           name="footLeft"
                                           type="checkbox"

                                    />

                                    <label htmlFor="footLeftId">L</label>
                                </div>

                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="footRightId"
                                           name="footRight"
                                           type="checkbox"

                                    />
                                    <label htmlFor="footRightId">R</label>
                                </div>

                            </div>

                        </td>
                        <td>Cervical Spine</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="cervicalSpine"
                                           name="cervicalSpine"
                                           type="checkbox"
                                    />

                                    <label htmlFor="cervicalSpine">&nbsp;</label>
                                </div>

                            </div>

                        </td>
                        <td className={styles.cost}>Cervical spine AND cervical spine in flexion and extension</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="cervicalSpineFlexion"
                                           name="cervicalSpineFlexion"
                                           type="checkbox"
                                    />

                                    <label htmlFor="cervicalSpineFlexion">&nbsp;</label>
                                </div>

                            </div>

                        </td>
                        <td>Hand</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="handLeftId"
                                           name="handLeft"
                                           type="checkbox"
                                    />

                                    <label htmlFor="handLeftId">L</label>
                                </div>

                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="handRightId"
                                           name="handRight"
                                           type="checkbox"

                                    />
                                    <label htmlFor="handRightId">R</label>
                                </div>

                            </div>

                        </td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Sacroiliac joints</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="sacroiliacJoints"
                                           name="sacroiliacJoints"
                                           type="checkbox"

                                    />

                                    <label htmlFor="sacroiliacJoints">&nbsp;</label>
                                </div>

                            </div>

                        </td>
                        <td>Thoracic spine</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="thoracicSpine"
                                           name="thoracicSpine"
                                           type="checkbox"
                                    />

                                    <label htmlFor="thoracicSpine">&nbsp;</label>
                                </div>

                            </div>

                        </td>
                        <td className={styles.cost}>Thoracic spine AND Thoracic spine weight bearing</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="thoracicSpineWeight"
                                           name="thoracicSpineWeight"
                                           type="checkbox"

                                    />

                                    <label htmlFor="thoracicSpineWeight">&nbsp;</label>
                                </div>

                            </div>

                        </td>
                        <td>Elbow</td>
                        <td>
                            <div className={styles.inputButtonSurround}>
                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="elbowLeftId"
                                           name="elbowLeft"
                                           type="checkbox"

                                    />

                                    <label htmlFor="elbowLeftId">L</label>
                                </div>

                                <div className={styles.inputButtonElementSurround}>
                                    <Field className={styles.inputCheckbox}
                                           id="elbowRightId"
                                           name="elbowRight"
                                           type="checkbox"
                                    />
                                    <label htmlFor="elbowRightId">R</label>
                                </div>

                            </div>

                        </td>
                        <td></td>
                        <td></td>
                    </tr>



                    </tbody>
                </table>
                <br/>
                <div className={styles.formRow}>
                    <div className={`${styles.question} ${styles.questionGrow}`}>
                        <label className={styles.defaultLabel} htmlFor="patientSpecialRequirements">Notes & Additional Requests <span className={styles.additionalInfo}>If you have additional requests for imaging or wish to highlight specific concerns please do so here.</span></label>
                        <Field name="mriSpecialRequest"
                               as="textarea"
                               autoComplete="off"
                               className={errors.mriSpecialRequest && touched.mriSpecialRequest ? `${styles.validateWarn} ${styles.defaultInput}` : `${styles.defaultInput}`}
                        />
                        <ErrorMessage className={styles.validationWarning} component="div"
                                      name={"mriSpecialRequest"}/>
                    </div>
                </div>

            </fieldset>

        )
    }
}
