import styles from '../styles/referral.module.scss'

import React, {Component} from "react";
import AuthService from "../util/AuthService";
import {Link} from "react-router-dom";
import {Button, ButtonSet} from "carbon-components-react";



export default class Logout extends Component {

    componentDidMount() {

        const user = AuthService.getCurrentUser();
        if (user) {
            AuthService.logout();
            window.location.reload();
        }
    }


    render() {

        return (
            <div>

                <div className={styles.pageTitleContainer}>
                    <h3 className={styles.pageTitle}>Logout</h3>
                </div>

                <fieldset className={styles.serverMessage}>
                    <p>You have been logged out of the service.</p>
                </fieldset>


                <p>&nbsp;</p>

                <ButtonSet>



                <Link to = "/login" className = "bx--btn--surround">
                    <Button as = "div" role = "button" kind="primary" tabIndex="10" size="md" type="button" className="bx--btn--wide">
                        Login
                    </Button>
                </Link>

                </ButtonSet>






            </div>
        )
    }

}

