import axios from "axios";

const API_URL = process.env.REACT_APP_API_TARGET;

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "/auth/login", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  recover(username) {
    return axios
        .post(API_URL + "/auth/recover", {
          username,
        })
        .then(response => {
           return response.data;
        });
  }

  logout() {
    sessionStorage.removeItem("user");
  }

  register(values) {
    return axios.post(API_URL + "/register", JSON.stringify(values), {headers: {'Content-Type': 'application/json'}});
  }


  //Using a different endpoint to the register endpoint to make life a little less confusing on the server auth config
  updateProfile(values) {
    return axios.put(API_URL + "/profile", JSON.stringify(values), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAuthToken()
      }
    });
  }

/*
{currentUser.roles &&
  currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
  */


  getProfile() {
    return axios.get(API_URL + "/profile", {
      headers: {
        'Authorization': 'Bearer ' + this.getAuthToken()
      }
    });
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('user'));;
  }


  getAuthToken() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user && user.accessToken) {
      return user.accessToken;
    } else {
      return
    }
  }




}

export default new AuthService();
